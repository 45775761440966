import React, { useEffect } from 'react';
import './about.scss';

const About: React.FC = () => {
  useEffect(() => {
    const handleScroll = () => {
      const columns = document.querySelectorAll('.about .column');

      columns.forEach((column, index) => {
        const h2Element = column.querySelector('h2') as HTMLElement;
        const pElement = column.querySelector('p') as HTMLElement;
        const h3Element = column.querySelector('h3') as HTMLElement;

        if (h2Element && pElement && h3Element) {
          const fadeInPoint = window.innerHeight * 0.75;
          const fadeOutPoint = window.innerHeight * 0.25;
          const bounding = column.getBoundingClientRect();

          if (bounding.top < fadeInPoint && bounding.bottom > fadeOutPoint) {
            const enterFraction = (fadeInPoint - bounding.top) / fadeInPoint;
            const translationValue = Math.max((1 - enterFraction) * 100, 0);
            const opacityValue = Math.min(enterFraction, 1);

            h2Element.style.transform = `translateY(${translationValue}px)`;
            pElement.style.transform = `translateY(${translationValue}px)`;
            h3Element.style.transform = `translateY(${translationValue}px)`;

            h2Element.style.opacity = `${opacityValue}`;
            pElement.style.opacity = `${opacityValue}`;
            h3Element.style.opacity = `${opacityValue}`;
          } else if (bounding.top > fadeOutPoint) {
            const exitFraction = (bounding.top - fadeOutPoint) / (window.innerHeight - fadeOutPoint);
            const translationValue = Math.min(exitFraction * 100, 100);
            const opacityValue = Math.max(1 - exitFraction, 0);

            h2Element.style.transform = `translateY(${translationValue}px)`;
            pElement.style.transform = `translateY(${translationValue}px)`;
            h3Element.style.transform = `translateY(${translationValue}px)`;

            h2Element.style.opacity = `${opacityValue}`;
            pElement.style.opacity = `${opacityValue}`;
            h3Element.style.opacity = `${opacityValue}`;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="about">
      <div className="row">
        <div className="column" id="column-2">
          <h3>Crafting Unique Visual Identities</h3>
          <h2>Logo Design</h2>
          <p></p>
        </div>
      </div>
      <div className="row">
        <div className="column" id="column-3">
          <div className="image-holder">
            <h3>Designing for the Modern Web</h3>
            <h2>Web Design & Development</h2>
            <p></p>
          </div>
        </div>
        <div className="column" id="column-4">
          <h3>Building Future-Proof Websites</h3>
          <h2>Graphic Design</h2>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default About;
