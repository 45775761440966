import React from 'react';
import { Element } from 'react-scroll';
import About from '../About/About';
import PortfolioSlider from '../PortfolioSlider/PortfolioGrid';
import HomeSection from '../HomeSection/HomeSection'; // Import the new component

const Home: React.FC = () => {
  return (
    <div className="home">
      <Element name="home" className="element">
        <HomeSection slides={[]} />
      </Element>
      <Element name="about-us" className="element">
        <div className="about-us" id="about">
          <About />
        </div>
      </Element>
      <Element name="portfolio-preview" className="element">
        <div className="portfolio-preview" id="portfolio">
          <PortfolioSlider />
        </div>
      </Element>
    </div>
  );
};

export default Home;
