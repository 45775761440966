import React, { useState, useEffect, useRef } from 'react';
import './PortfolioGrid.scss';

interface ContentBlock {
  type: string;
  text: string;
}

interface PortfolioItem {
  id: string;
  imageUrl: string;
  title: string;
  description: string;
  ctaText: string;
  ctaLink: string;
  size: 'small' | 'large';
  content?: ContentBlock[];
}

const PortfolioGrid: React.FC = () => {
  const [portfolioItems, setPortfolioItems] = useState<PortfolioItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<PortfolioItem | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetch('portfolioItems.json')
      .then((response) => response.json())
      .then((data) => setPortfolioItems(data));
  }, []);

  const openModal = (item: PortfolioItem) => {
    setSelectedItem(item);
    document.body.classList.add('modal-open'); // Disable scrolling on the main page
    if (modalRef.current) {
      modalRef.current.style.display = 'block';
      setTimeout(() => {
        modalRef.current?.classList.add('open');
      }, 10); // Small delay to ensure transition occurs
    }
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.remove('open');
      setTimeout(() => {
        modalRef.current!.style.display = 'none';
        document.body.classList.remove('modal-open'); // Re-enable scrolling on the main page
      }, 300); // Match this delay to the CSS transition duration
    }
    setSelectedItem(null);
  };

  const renderContent = (content?: ContentBlock[]) => {
    return content?.map((block, index) => {
      switch (block.type) {
        case 'p':
          return <p key={index}>{block.text}</p>;
        case 'h4':
          return <h4 key={index}>{block.text}</h4>;
        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="portfolio-grid">
        {portfolioItems.map((item) => (
          <div
            key={item.id}
            className={`portfolio-item portfolio-item--${item.size}`}
            onClick={() => openModal(item)}
          >
            <img src={item.imageUrl} alt={item.title} />
            <div className="portfolio-item__overlay">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <button className="cta-button">Learn More</button>
            </div>
          </div>
        ))}

        <div ref={modalRef} className="modal">
          {selectedItem && (
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <img
                src={selectedItem.imageUrl}
                alt={selectedItem.title}
                className="modal-header-image"
              />
              <h2 className="modal-title">{selectedItem.title}</h2>
              <div className="modal-body">{renderContent(selectedItem.content)}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PortfolioGrid;
