import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { ReactComponent as InitialLogo } from './ohora.svg'; // Initial logo before scroll
import { ReactComponent as ScrolledLogo } from './ohora2.svg'; // Logo after scroll
import './NavBar.scss';

const NavBar: React.FC = () => {
  const [scrolled, setScrolled] = useState(0); // Store the scroll progress (0 to 1)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const maxScroll = document.documentElement.scrollHeight - windowHeight;

      // Make scroll more sensitive by multiplying the scroll progress by a factor
      const scrollProgress = Math.min(scrollTop / (maxScroll / 20), 1); // Fast scaling (3 times faster)
      setScrolled(scrollProgress); // Update the scroll progress state
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className="logo-wrapper">
        {/* We use scrolled state to control scaling and opacity */}
        <div className="logo-container">
          <InitialLogo
            style={{
              transform: `scale(${1 - scrolled})`,
              opacity: `${1 - scrolled}`,
            }}
          />
        </div>
        <div className="logo-container scaled-logo">
          <ScrolledLogo
            style={{
              transform: `scale(${scrolled})`,
              opacity: `${scrolled}`,
            }}
          />
        </div>
      </div>
      <nav className="new-navbar">
        <div className="new-navbar__links">
          <ScrollLink to="home" smooth={true} duration={500} spy={true} offset={-70} activeClass="active">
            <i className="fa fa-home"></i>
          </ScrollLink>
          <ScrollLink to="about-us" smooth={true} duration={500} spy={true} offset={-70} activeClass="active">
            <i className="fa fa-info"></i>
          </ScrollLink>
          <ScrollLink to="portfolio-preview" smooth={true} duration={500} spy={true} offset={-70} activeClass="active">
            <i className="fa fa-briefcase"></i>
          </ScrollLink>
          <ScrollLink to="contact-us" smooth={true} duration={500} spy={true} offset={-70} activeClass="active">
            <i className="fa fa-envelope"></i>
          </ScrollLink>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
