import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import PortfolioSlider from './components/PortfolioSlider/PortfolioGrid';
import NavBar from './components/NavBar/NavBar';
import './App.scss';
import ErrorBoundary from './ErrorBoundary';
import Loader from './components/Loader/Loader'; // Import the new Loader

const App: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);

  return (
    <div className="app">
      <ErrorBoundary>
        {!loadingComplete && <Loader onComplete={() => setLoadingComplete(true)} />}
        {loadingComplete && (
          <>
            <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<PortfolioSlider />} />
            </Routes>
          </>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default App;
