import React, { useState, useEffect } from 'react';
import './Loader.scss';
import { ReactComponent as LogoSVG } from './ohora.svg'; // Full SVG logo

const wordsMap: { M: string[]; N: string[]; M2: string[]; L: string[] } = {
  M: ["Minimal", "Modern", "Monolithic"],
  N: ["Neutral", "Natural"],
  M2: ["Modular","Muted", "Monochrome"],
  L: ["Linear", "Layered", "Light"]
};

const Loader: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const [currentLetter, setCurrentLetter] = useState<'M' | 'N' | 'M2' | 'L'>('M');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentPhase, setCurrentPhase] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false); // Track logo visibility
  const [suckedIn, setSuckedIn] = useState(false); // Track suck-in state

  useEffect(() => {
    const letters: ('M' | 'N' | 'M2' |'L')[] = ['M', 'N', 'M2', 'L'];

    if (currentPhase < letters.length) {
      const wordList = wordsMap[letters[currentPhase]];

      if (wordList && wordList.length > 0) {
        const wordCycle = setInterval(() => {
          setCurrentWordIndex((prev) => (prev + 1) % wordList.length);
        }, 11); // Cycle through words every 300ms for faster transitions

        const letterDisplayTimeout = setTimeout(() => {
          setCurrentPhase((prev) => prev + 1);
          if (letters[currentPhase + 1]) {
            setCurrentLetter(letters[currentPhase + 1]);
          } else {
            setLogoVisible(true); // Show logo after all words have been cycled
          }
          setCurrentWordIndex(0);
        }, wordList.length * 200); // Move to next phase after cycling words

        return () => {
          clearInterval(wordCycle);
          clearTimeout(letterDisplayTimeout);
        };
      }
    } else if (!completed && logoVisible) {
      setTimeout(() => {
        setSuckedIn(true); // Trigger the suck-in effect
        setTimeout(() => {
          setCompleted(true);
          onComplete(); // Transition to the navbar
        }, 1000); // Suck in the logo for 1 second before transitioning
      }, 1000); // Show logo for 1 second before sucking it in
    }
  }, [currentPhase, completed, onComplete, logoVisible]);

  return (
    <div className={`loader-screen ${completed ? 'completed' : ''}`}>
      {!completed && !logoVisible && (
        <div className="word-cycle">
          <h1>{wordsMap[currentLetter]?.[currentWordIndex]}</h1>
        </div>
      )}
      {logoVisible && (
        <div className={`logo-display ${suckedIn ? 'sucked-in' : ''}`}>
          <LogoSVG className="centered-logo" />
        </div>
      )}
    </div>
  );
};

export default Loader;
