import React, { useRef, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../Home/home.scss';

SwiperCore.use([]);

interface Slide {
  imageUrl: string;
  description: string;
}

interface HomeSectionProps {
  slides: Slide[];
}

const HomeSection: React.FC<HomeSectionProps> = () => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [slides, setSlides] = useState<Slide[]>([]);

  useEffect(() => {

    fetch('/slides.json')
    .then((response) => response.json())
    .then((data) => setSlides(data));
    
    const handleScroll = () => {
      if (swiperRef.current) {
        const scrollY = window.scrollY;
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        const scrollFraction = scrollY / maxScroll;
        const totalSlides = slides.length;
        const slideIndex = scrollFraction * (totalSlides - 1);
        swiperRef.current.setTranslate(-slideIndex * (window.innerWidth / 1.7));
      }

      const applyParallaxEffect = (element: HTMLElement, fadeOutPoint: number) => {
        const distanceFromTop = element.getBoundingClientRect().top;

        if (distanceFromTop < fadeOutPoint) {
          const translationValue = Math.min(window.scrollY, window.innerWidth);
          element.style.transform = `translateX(${translationValue}px)`;
          element.style.opacity = `${1 - window.scrollY / fadeOutPoint}`;
        } else {
          element.style.transform = 'translateX(0)';
          element.style.opacity = '1';
        }
      };

      const textElements = document.querySelectorAll('.home-section .left-column [data-parallax="true"]');
      textElements.forEach((element) => {
        applyParallaxEffect(element as HTMLElement, 100);
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [slides]);

  return (
    <div className="home-section">
      <div className="glassmorphism-overlay">
      </div>
      <div className="left-column">
        <div className="tagline" data-parallax="true">Main Quest</div>
        <h1 data-parallax="true">Follow the right inspiration</h1>
        <div className="tagline" data-parallax="true">Side Quest</div>
        <h3 data-parallax="true">Bring Life to Ideas</h3>
        <p data-parallax="true">
          Compelling brand identities and intuitive websites that resonate with your audience, ensuring your business stands out in the digital landscape.
        </p>
      </div>
      <div className="right-column">
        <Swiper
          onSwiper={(swiper: SwiperCore) => {
            swiperRef.current = swiper;
          }}
          direction="horizontal"
          slidesPerView={2.7}
          spaceBetween={0}
          pagination={false}
          className="mySwiper"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="box">
              <img src={slide.imageUrl} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSection;
